import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/Pages/LandingPage/LandingPage";
import HomePage from "./components/Pages/HomePage/HomePage";
import Contact from "./components/Pages/Support/Support";
import RegisterPage from "./components/Pages/Register/Register";
import Login from "./components/Pages/Login/Login";
import BenefitDetail from "./components/Pages/BenefitDetail/BenefitDetail";
import Verification from "./components/Pages/Verification/Verification";
import Verified from "./components/Pages/Verified/Verified";
import ActivityPage from "./components/Pages/Activities/ActivityPage";
import TuristicPage from "./components/Pages/Activities/Turistic/TuristicPage";
import SocialPage from "./components/Pages/Activities/Social/SocialPage";
import AcademicPage from "./components/Pages/Activities/Academic/AcademicPage";
import AcademicDetail from "./components/Pages/Activities/Academic/AcademicDetail";
import SocialDetail from "./components/Pages/Activities/Social/SocialDetail";
import TuristicDetail from "./components/Pages/Activities/Turistic/TuristicDetail";
import Lodgment from "./components/Pages/Lodgment/Lodgment";
import ProtectedRoute from "./components/routes/ProtectedRoutes";

const App = () => {
  return (
    <div className="bg-pearl min-h-screen text-roboto">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Register" element={<RegisterPage />} />
          <Route path="/Verification" element={<Verification />} />
          <Route path="/Verified/:token" element={<Verified />} />

          <Route path="/Beneficios" element={<ProtectedRoute element={<HomePage />} />} />
          <Route path="/Actividades" element={<ProtectedRoute element={<ActivityPage />}/>}/>
          <Route path="/Actividades/:university_id/turisticPage" element={<ProtectedRoute element={<TuristicPage />}/> }/>
          <Route path="/Actividades/:university_id/socialPage" element={<ProtectedRoute element={<SocialPage />}/>}/>
          <Route path="/Actividades/:university_id/academicPage" element={<ProtectedRoute element={<AcademicPage />}/>}/>
          <Route path="/Actividades/:id/academicDetail" element={<ProtectedRoute element={<AcademicDetail />} />} />
          <Route path="/Actividades/:id/socialDetail" element={<ProtectedRoute element={<SocialDetail/>} />} />
          <Route path="/Actividades/:id/turisticDetail" element={<ProtectedRoute element={<TuristicDetail/>} />} />
          <Route path="/Hospedajes" element={<ProtectedRoute element={<Lodgment/>} />} />
          <Route path="/Beneficio/:benefitId" element={<ProtectedRoute element={<BenefitDetail/>} />} />
      </Routes>
    </Router>
    </div>
  );
};

export default App;
